import logo from './logo.png';
import './App.css';

function App() {
    return (
        <div className="App">
            <header className="App-header">
                <img src={logo} className="App-logo" alt="logo" />
                <p style={{ fontWeight: '700' }}>
                    Coming Soon ...
                </p>
            </header>
        </div>
    );
}

export default App;
